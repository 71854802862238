@use '/src/styles/variables/colors';
@use '/src/styles/mixins/font-sizes';

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 2.2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: colors.$nh-green;
        border-color: colors.$nh-green;

        .checkmarkIcon {
          display: block;
        }
      }

      ~ .labelText {
        color: colors.$grey-6;
      }
    }
  }

  &:hover {
    .input {
      ~ .checkmark {
        background-color: colors.$grey-3;
        border: 1px solid colors.$grey-5;
      }

      &:checked ~ .checkmark {
        background-color: colors.$nh-dark-green;
        border: 1px solid colors.$grey-5;
      }
    }
  }

  .checkmarkIcon {
    display: none;

    path {
      stroke: colors.$nh-white;
    }
  }
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: colors.$nh-white;
  border: 1px solid colors.$grey-5;
  border-radius: 3px;
}

.labelText {
  color: colors.$grey-5;
  @include font-sizes.text;
}
