@use '../../styles/variables/colors';

.testimonial {
  .titleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 22px;

    h4 {
      color: colors.$grey-5;
      font-size: 2.4rem;
    }
    
    .icon {
      margin-right: 20px;
    }
  }

  p {
    color: colors.$grey-5;
  }
}