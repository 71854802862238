@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/font-sizes';
@use '../../styles/mixins/font-styles';
@use '../../styles/mixins/mq';

.viewMoreWrapper {
    padding-left: 24px;
    padding-right: 24px;

    @include mq.mq($from: largeTablet) {
        max-width: 360px;
        margin: 0 auto;
    }
}

.viewMore {
    cursor: pointer;
    font-family: fonts.$bodyFont;
    color: black;
    line-height: fonts.$bodyLineHeight;
    position: relative;
    display: flex;
    border: 1px colors.$grey-3 solid;
    font-size: 1.6rem;
    padding: 16px 16px 16px 16px !important;
    border-radius: 8px;
    background-color: colors.$nh-white;
    justify-content: center;
    text-decoration: underline;

    @include mq.mq($from: tablet) {
      margin-top: 24px;
    }

    &:hover {
        border-color: colors.$nh-green;
    }
}

.gymCardWrapper {
    display: flex;
}

.gymCardList { 
  @include mq.mq($from: tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 8px 24px 0;
  }

  @include mq.mq($from: largeTablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}