@use '/src/styles/variables/colors';

.paymentContainers {
  max-width: 600px;
}

.paymentSection {
  position: relative;
  margin-bottom: 25px;
}

.paymentBox {
  border: 1px solid colors.$grey-3;
  border-radius: 12px;
  cursor: default;
  margin-block: 8px;
  transition: margin .15s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity .3s ease-out;
}

.paymentMethodWrapper {
  padding: 16px;
}

.paymentSeperator {
  display: flex;
  width: 100%;
  align-items: center;
  margin-block: 30px;
}

.orderInfoSection {
  margin-bottom: 20px;

  h4 {
    margin-bottom: 5px;
  }
}

.seperatorLine {
  flex: 1 1 100%;
  margin: 0 10px;
  border-top: 1px solid colors.$grey-3;
}

.googlePayWrapper {
  border-radius: 500px;
  border: 2px solid #3C4043;
  padding: 2px 5px;
  display: flex;
  align-items: center;
}

.hr {
  height: 1px; 
  background-color: colors.$grey-4;
  margin-bottom: 45px;
}

.errorMessageWrapper {
  margin-bottom: 15px;
}

.paymentError {
  border-radius: 7px;
  background-color: colors.$grey-4;
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
  text-align: center;
  position: absolute;
  width: 600px;
  height: 100%;
  min-height: 350px;
  z-index: 1;

  .paymentErrorBtn {
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px colors.$grey-8;
    background-color: colors.$grey-3;
    padding: 8px 40px;
    cursor: pointer;

    &:hover, &:focus {
      background-color: colors.$grey-1;
    }
  }
}