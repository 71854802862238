@use '../../styles/variables/colors';

$color-amex-blue: #0079c2;

.acceptedCards {
  display: inline-flex;
}

.card {
  border: 1px solid colors.$grey-3;
  border-radius: 4px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-inline: 3px;

  &.amex {
    background-color: $color-amex-blue;
    justify-content: flex-end;
    padding-right: 0;
  }

  .cardImage {
    max-width: 100%;
    max-height: 100%;
  }
}