@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';

$Radio__height: 32px;
$Radio__width: 32px;
$Radio__space-between-btn-and-text: 12px;

.root {
  min-height: 32px;
  display: block;
  position: relative;
  padding-left: calc($Radio__width + $Radio__space-between-btn-and-text);
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .radio {
        background-color: colors.$nh-green;
      }
    }

    &:checked {
      ~ .radio {
        &:after {
          display: block;
        }
        &:before {
          content: "t";
          color: colors.$nh-white;
          font-family: fonts.$iconFont;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .label {
    font-size: 1.6rem;
    color: colors.$nh-black;
    min-height: 32px;
    display: flex;
    align-items: center;
  }

  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: $Radio__height;
    width: $Radio__width;
    background-color: colors.$nh-white;
    border-radius: 50%;
    border: 2px solid colors.$grey-3;
    transition: 250ms all;
    font-size: 1.2rem;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: -2px;
      left: -2px;
      width: $Radio__width;
      height: $Radio__height;
      border-radius: 50%;
      background: colors.$nh-green;
    }
  }
  
  &:hover {
    .input {
      ~ .radio {
        background-color: colors.$grey-3;
      }
    }
  }
}
