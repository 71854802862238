@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';

$mobile-spacing: 25px;
$desktop-spacing: 40px;

.body {
  padding-bottom: 0;  
}

.intro {
  display: flex;
  flex-direction: column-reverse;
  
  @include mq.mq($from: tablet) {
    flex-direction: row;
  }

  .introCopy {
    flex: 1 1 66%;
  }

  .introImg {
    padding-bottom: 10px;

    @include mq.mq($from: tablet) {
      flex: 1 1 33%;
      display: block;
      padding-left: 25px;
      padding-bottom: 15px;
    }

    img {
      width: 100%;
    }
  }
}

.cartSummarySection {
  margin-top: $mobile-spacing;
  padding-block: $mobile-spacing;
  border-block: 1px solid colors.$grey-3;

  @include mq.mq($from: tablet) {
    margin-top: $desktop-spacing;
    padding-block: $desktop-spacing;
  }

  h2 {
    margin-block: $mobile-spacing;

    @include mq.mq($from: tablet) {
      margin-block: $desktop-spacing
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .orderDetail {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-bottom: 8px;
    }
  }
}

.contentSection {
  border-bottom: 1px solid colors.$grey-3;
  padding-block: $mobile-spacing;

  @include mq.mq($from: tablet) {
    padding-block: $desktop-spacing;
  }
}

.paymentType {
  display: flex;
  align-items: center;
}

.paymentBadge {
  width: 70px;
  margin-right: 15px;
}