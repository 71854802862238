@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/font-sizes';

.mask {
  background: rgba(51, 51, 51, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 100;
}

.autoComplete {
  z-index: 150;
  position: relative;

  .results {
    margin-top: 6px;
    background-color: colors.$nh-white;
    position: absolute;
    width: 100%;
    box-shadow: 0px 4px 4px 0px colors.$grey-4;

    .placeList {
      list-style: none;
      padding: 0;
      margin: 0;

      .placeItem {
        @include font-sizes.text;
        padding: 12px;
        cursor: pointer;
        border-bottom: colors.$grey-3 1px solid;
        color: colors.$grey-6;
  
        &:hover {
          background-color: colors.$nh-pale-green;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
