@use '../../styles/mixins/mq';
@use '../../styles/variables/colors';

.filterOption {
  @include mq.mq($until: tablet) {
    padding: 8px 16px;

    label {
      margin-bottom: 0;
    }
  }
}

.optionText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.8rem;
}

.checkedFilter {
  @include mq.mq($until: tablet) {
    background-color: colors.$nh-pale-green;
  }
}
