$headingFont: 'NuffieldSans';
$bodyFont: 'Poppins';
$bodySemiBoldFont: 'PoppinsSemiBold';
$bodyHeavyFont: 'PoppinsHeavy';
$bodyBlackFont: 'PoppinsBlack';
$iconFont: 'NuffieldIcons';

$bodyLineHeight: 23px;

$lexendDeca: 'LexendDeca';

$bodyBaseFontSize: 10;