@use '../../styles/mixins/mq';
@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';

.root {
    // This is to make the card the full height of the grid cell
    @include mq.mq($from: tablet) {
        height: 100%;

        &.cardOpen {
            margin-bottom: -24px;
        }

        .box {
            height: 100%;
        }
    }

    .box {
        border-radius: 8px;
        margin-bottom: 0;
        box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.15);

        .subheading {
            font-size: 2rem;
        }

        .profile {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .profileRow {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            display: flex;
            background: colors.$nh-white;

            .profileImg {
                max-width: 123px;
                max-height: 123px;
                overflow: hidden;
                
                img {
                    object-fit: cover;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 8px;
                }
            }

            .profileHeadings {
                padding: 12px 8px 0 17px;
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                border-radius: 0 8px 0 0;
                background-color: colors.$nh-white;

                .profileTitles {
                  flex: 1 1 auto;
                  @include mq.mq($until: tablet) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }

                  .title {
                    margin-bottom: 5px;
                    font-size: 2.1rem;
                    line-height: 26px;

                    @include mq.mq($from: tablet) {
                        margin-bottom: 0;
                        font-size: 2.4rem;
                    }
                  }

                  .role {
                    color: colors.$grey-5;
                    font-size: 2.1rem;

                    @include mq.mq($from: tablet) {
                        font-size: 1.8rem;
                        margin: 10px 0px;
                    }
                  }
                }

                .bioBtn {
                    display: none;
                    border-radius: 8px;
                    border: 1px solid colors.$grey-6;
                    height: 32px;
                    padding: 6px 6px 6px 14px;
                    text-decoration: underline;
                    background: colors.$nh-white;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    min-width: 146px;
                    text-align: left;
                    
                    @include mq.mq($from: tablet) {
                        display: inline-flex;
                    }
                    
                    span {
                        position: relative;
                        flex: 1 1 auto;
                        z-index: 50;
                        font-size: 1.6rem;
                        color: colors.$nh-dark-green;
                        font-family: fonts.$bodyFont;
                    }
                    
                    svg {
                      margin-left: 6px;
                    }
                }
            }
        }

        .summary {
            margin-top: 16px;
        }

        .testimonial {
            background-color: colors.$nh-white;
            padding: 33px 16px;
        }

        .ctaBtn {
            display: flex;
            justify-content: center;
            padding: 0 0 16px;
            @include mq.mq($from: tablet) {
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .profileDetails {
            padding: 0 16px 24px;
        }

        .qualifications {
            .subheading {
                padding-top: 0;

                @include mq.mq($from: tablet) {
                    padding-top: 16px;
                }
            }
        }
    }

    .specialities {
        padding: 16px 16px 0;
        flex: 1 1 100%;
    }

    .collapsiblePanelInner {
        padding-top: 16px;
        -webkit-box-shadow: inset 0px 10px 8px -6px rgba(204,204,204,0.15); 
        box-shadow: inset 0px 10px 8px -6px rgba(204,204,204,0.15);
    }

    .tickBulletPoint {
        position: relative;
        bottom: 2px;
    }

    .biography {
        color: colors.$grey-5;
        line-height: 23px;
    }

    &.cardOpen {
        .box {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 2px solid colors.$nh-dark-green;
        }

        &:after {
            content: '';
            width: 0; 
            height: 0;
            display: block;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 24px solid colors.$nh-dark-green;
            margin: 0 auto;
        }
    }

    @include mq.mq($from: tablet) {
        &.cardOpen {
            .box {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 2px solid colors.$nh-dark-green;
            }

            &:after {
                content: '';
                width: 0; 
                height: 0;
                display: block;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 24px solid colors.$nh-dark-green;
                margin: 0 auto;
            }
        }
    }
}

.mobileBioTrigger {
    background-color: colors.$grey-1;
    border-radius: 0 0 8px 8px;

    .mobileBioTriggerBtn {
        width: 100%;
        font-size: 1.6rem;
        padding: 8px 16px;
        justify-content: space-between;
    }

    .mobileBioTriggerIcon {
        fill: colors.$nh-black;
        stroke: colors.$nh-black;

        path {
            fill: colors.$nh-black;
            stroke: colors.$nh-black;
        }
    }

    @include mq.mq($from: tablet) {
        display: none;
    }
}

.cancelTrainerModal {
    .btnRow {
        display: flex;
        margin: 24px 0px 12px 0px;
        justify-content: space-between;
    }
}
