@use '../variables/fonts';
@use '../variables/colors';
@use '../mixins/font-sizes';
@use '../mixins/font-styles';
@use '../mixins/mq';

@font-face {
    font-family: fonts.$bodyFont;
    src: local(fonts.$bodyFont),
        url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: fonts.$bodyFont;
    src: local(fonts.$bodyFont),
        url("../../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: fonts.$bodyFont;
    src: local(fonts.$bodySemiBoldFont),
        url("../../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: fonts.$bodyFont;
    src: local(fonts.$bodyHeavyFont),
        url("../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: fonts.$bodyFont;
    src: local(fonts.$bodyBlackFont),
        url("../../fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: fonts.$headingFont;
    src: local("NuffieldSans"),
        url("../../fonts/NuffieldSans/NuffieldSans-Regular.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: fonts.$headingFont;
    src: local("NuffieldSans"),
        url("../../fonts/NuffieldSans/NuffieldSans-Bold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "NuffieldIcons";
    src: local("NuffieldIcons"),
        url("../../fonts/NuffieldIcons/nuffieldicons.woff") format("woff");
}

html, body {
    font-size: #{fonts.$bodyBaseFontSize + 'px'};
    font-family: fonts.$bodyFont;
    color: colors.$nh-black;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @include font-styles.headings;
    margin-bottom: 16px;

    &.noMargin {
        margin-bottom: 0;
    }
}

h1, .h1 {
    @include font-sizes.h1;
}

h2, .h2 {
    @include font-sizes.h2;
}

h3, .h3 {
    @include font-sizes.h3;
    font-weight: 700; // use NuffieldSans-Bold by setting the font-weight
}

h4, .h4 {
    @include font-sizes.h4;
    font-weight: 700;
}

h5, .h5 {
    @include font-sizes.h5;
    font-weight: 700;
}

h6, .h6 {
    @include font-sizes.h6;
    font-weight: 700;
}

p {
    @include font-styles.text;
    margin-bottom: 16px;
    line-height: 26px;

    @include mq.mq($from: tablet) {
        line-height: 30px;
    }

    &.noMargin {
        margin-bottom: 0;
    }
}

body, .text {
    @include font-styles.text;
}

.small-text {
    @include font-styles.text;
    font-size: 1.4rem;
    line-height: 24px;

    @include mq.mq($from: tablet) {
        font-size: 1.6rem;
        line-height: 26px;
    }
}

.secondary-text {
    @include font-styles.text-secondary;
}

.text-banner-desc {
    @include font-styles.text-banner-desc;
}

ul, ol {
    @include font-sizes.text;
    color: colors.$grey-5;
    margin-bottom: 15px;
    line-height: fonts.$bodyLineHeight;

    &.noStyles {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
}

ul {
    list-style: disc;
    padding-left: 20px;
}

strong {
    font-weight: 700;
}

// Icon font taken from NH site
.icon {
  &:after {
    font-family: fonts.$iconFont;
    line-height: 0;
    text-transform: none;
    font-size: 20px;
    width: 20px;
    height: 20px;
    color: colors.$nh-green;
  }

  &--alert:after {
      content: "£";
  }

  &--tick:after {
      content: "t";
  }

  &--arrow-up:after {
      content: "u";
  }

  &--arrow-down:after {
      content: "q";
  }

  &--arrow-left:after {
      content: "<";
  }

  &--arrow-right:after {
      content: ">";
  }

  &--arrow-back:after {
      content: "«";
  }

  &--circled-chevron-right:after {
      content: "W";
  }

  &--circled-chevron-down:after {
      content: "V";
  }

  &--circled-chevron-up:after {
      content: "^";
  }

  &--chevron-down:after {
      content: "Y";
  }

  &--chevron-cross:after {
      content: "X";
  }

  &--healthscore:after {
      content: "¢";
  }

  &--physios:after {
      content: "T";
  }

  &--hospitals:after {
      content: "T";
  }

  &--search:after {
      content: "?";
  }

  &--user:after {
      content: "l";
  }

  &--gyms:after {
      content: "g";
  }

  &--hospitals:after {
      content: "U";
  }

  &--health-check:after {
      content: "h";
  }

  &--phone:after,
  &--call:after,
  &--telephone:after {
      content: "i";
  }

  &--envelope:after {
      content: "p";
  }

  &--basket:after {
      content: "$";
  }

  &--user-menu:after {
      content: "@";
  }

  &--user-down:after {
      content: "(";
  }

  &--user-up:after {
      content: ")";
  }
}

a {
    @include font-styles.text-link;
}