@use '../../styles/mixins/mq';
@use '../../styles/mixins/font-sizes';
@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';

.LocationListContainer {
  button {
    height: 100%;
    width: 100%;
  }
}

.changeLocation span {
  @include font-sizes.text;
  font-weight: 900;  
  position: absolute;
  top: 25px;
  left: 0;
  color: colors.$nh-green;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.locationMessage {
    display: flex;
    justify-content: center;
    margin-top: 52px;
    margin-left: auto;
    margin-right: auto;

    @include mq.mq($from: largeTablet) {
        margin-top: auto;
        max-width: 450px;
    }

    @include mq.mq($from: desktop) {
        max-width: 600px;
    }

    p {
        background-color: colors.$nh-pale-green;
        padding: 16px 24px;
        width: 100%;
        text-align: center;

        @include mq.mq($from: largeTablet) {
            width: auto;
        }
    }
}