@use '/src/styles/variables/colors';
@use '/src/styles/mixins/font-sizes';
@use '../../styles/mixins/mq';

.checkboxContainer {

  input[type='checkbox'] {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border: solid 3px colors.$grey-8;
    border-radius: 5px;
    margin-right: 16px;
    flex: none;
    margin-top: 8px;
    @include mq.mq($from: tablet) {
      margin-top: 0;
    }

    &:checked {
      background-color: colors.$nh-green;
      border-color: colors.$nh-green;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none"><g clip-path="url(%23clip0_1679_258)"><path d="M14.2051 0.696899L5.06284 11.7685L0.788086 6.71769" stroke="white" stroke-width="3" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_1679_258"><rect width="15" height="13.4211" fill="white"/></clipPath></defs></svg>');
      background-repeat: no-repeat;
      background-position: center;

      ~.labelText {
        color: colors.$grey-6;
      }
    }

  }

  label {
    display: flex;

    @include mq.mq($from: tablet) {
      align-items: center;
    }
  }
}