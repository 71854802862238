@use '../../styles/variables/colors';

.customBulletPoints > ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 8px;

  li {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    font-size: 1.8rem;

    svg {
      display: block;
      flex: 1 1 30px;
      margin-right: 15px;
    }

    .innerText {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      color: colors.$grey-5;
    }
  }
}
