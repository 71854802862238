@use './styles/global/reset';
@use './styles/global/fonts';
@use './styles/mixins/mq';

@include mq.mq($until: tablet) {
  .adyen-checkout__field-wrapper>.adyen-checkout__field {
    &.adyen-checkout__field__exp-date {
      margin-right: 0;
      padding-right: 10px;
    }
    &.adyen-checkout__field__cvc {
      margin-left: 0;
      padding-left: 10px;
    }
  }
}

@include mq.mq($until: largeMobile) {
  .adyen-checkout__card__exp-cvc {
    flex-direction: column;
  }

  .adyen-checkout__field-wrapper>.adyen-checkout__field {
    &.adyen-checkout__field__exp-date {
      padding: 0;
    }

    &.adyen-checkout__field__cvc {
      margin-top: 16px;
      padding: 0;
    }
  }
}