@use './mq';
@use '../variables/spacings';

@mixin full-bleed {
  @include mq.mq($from: tablet) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  @include mq.mq($until: tablet) {
    margin-left: #{'-' + spacings.$mobile-gutters};
    margin-right: #{'-' + spacings.$mobile-gutters};
  }
}