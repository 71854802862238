@use '../../styles/mixins/mq';
@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/font-sizes';
@use '../../styles/mixins/font-styles';

$btn-padding-x-desktop: 32px;
$btn-padding-y-desktop: 16px;
$btn-padding-x-mobile: 24px;
$btn-padding-y-mobile: 12px;

.root {
  cursor: pointer;
  border: none;
  border-radius: 500px;
  display: flex;
  font-size: 1.8rem;
  align-items: center;
  justify-content: center;
  padding: $btn-padding-y-mobile $btn-padding-x-mobile;
  transition: 250ms all;
  text-align: center;

  @include mq.mq($from: tablet) {
    font-size: 2rem;
    padding: $btn-padding-y-desktop $btn-padding-x-desktop;
  }
  
  &.primary {    
    color: colors.$nh-white;
    font-weight: 700;
    background-color: colors.$nh-dark-green;

    &:disabled {
      background-color: colors.$grey-4;
    }

    &:hover {
      background-color: colors.$balance-green-dark;
    }
  }

  &.secondary {
    color: colors.$nh-dark-green;
    font-weight: 700;
    background-color: colors.$nh-white;
    border: 2px solid colors.$nh-dark-green;
    padding: calc($btn-padding-y-mobile - 2px) calc($btn-padding-x-mobile - 2px); // Remove 2px to compensate for border
    
    &:hover {
      color: colors.$balance-green-dark;
      border-color: colors.$balance-green-dark;
    }

    &.disabled, &:hover.disabled {
      background-color: colors.$nh-white;
      color: colors.$grey-5;
      border-color: colors.$grey-8;
    }

    @include mq.mq($from: tablet) {
      font-size: 2rem;
      padding: $btn-padding-y-desktop $btn-padding-x-desktop;
    }
  }

  &.danger {    
    color: colors.$nh-white;
    font-weight: 700;
    background-color: colors.$danger-red;
    
    &:hover {
      background-color: colors.$dark-burgundy;
    }
  }

  &.disabled {
    background-color: colors.$grey-4;
    color: colors.$grey-5;
    font-weight: 700;
    cursor: default;

    &:hover {
      background-color: colors.$grey-4;
    }
  }

  &.text {
    font-weight: 500;
    background-color: transparent;
    color: colors.$grey-6;
    text-decoration: underline;
    padding: 0;
    transition: color 250ms;

    &:hover {
      font-weight: 700;
    }

    &.disabled {
      background-color: transparent;
      color: colors.$grey-7;
      cursor: default;
      font-weight: 500;
    }
  }
}

.fullWidth {
  width: 100%;
  display: block;
}

.inline {
  display: inline-block;
}