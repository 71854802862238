@use '../../styles/variables/colors';

.filterPills {
  margin-bottom: 24px;
}

.pill {
  border-radius: 500px;
  padding: 8px 24px;
  border: 1px solid colors.$nh-green;
  background-color: colors.$nh-pale-green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 0px colors.$grey-5;
  margin: 0 5px 10px;
  color: colors.$grey-6;
  font-weight: 700;
  cursor: pointer;

  .pillCloseIcon {
    margin-left: 10px;
    path {
      fill: colors.$grey-6;
    }
  }
}

.pillWrapper {
  display: inline-block;
}

.pillCloseIconWrapper {
  width: 24px;
  height: 24px;
}