@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/mq';
@use '../../styles/mixins/font-styles';
@use '/src/styles/mixins/font-sizes';

.discountBadge {
  padding: 2px 8px;
  display: block;
  font-size: 1.6rem;
}

.discountBadgeDeletable {
  padding: 2px 48px 2px 8px;
  display: block;
  font-size: 1.6rem;
}

.removeVoucher {
  position: absolute;
  right: 10px;
  cursor: pointer;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none"><path d="M15.38 14.04L16.22 14.88L15.38 15.72L14.54 14.88L15.38 14.04ZM15.38 5.28003L16.22 6.12003L11.84 10.5L14.84 13.5L14 14.34L11 11.34L6.62003 15.72L5.78003 14.88L10.16 10.5L5.78003 6.12003L6.62003 5.28003L11 9.66003L15.38 5.28003Z" fill="white"/><path d="M21.2001 10.5C21.2001 16.14 16.64 20.7001 11 20.7001C5.36005 20.7001 0.800049 16.14 0.800049 10.5C0.800049 4.86005 5.36005 0.300049 11 0.300049C16.64 0.300049 21.2001 4.86005 21.2001 10.5ZM20 10.5C20 5.52005 15.98 1.50005 11 1.50005C6.02005 1.50005 2.00005 5.52005 2.00005 10.5C2.00005 15.48 6.02005 19.5 11 19.5C15.98 19.5 20 15.48 20 10.5Z" fill="white"/></svg>');
}

.priceRow {
  margin-bottom: 40px;


  @include mq.mq($from: tablet) {
    display: flex;
  }

  div {
    margin: 0 0 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq.mq($from: tablet) {
      margin: 0 24px 0 0;
      display: inline-block;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.priceLabel {
  font-size: 1.8rem;
  font-weight: 700;
  color: colors.$grey-6;
}

.price {
  color: colors.$grey-6;
  @include font-sizes.h4;
}

.strikethroughPrice {
  text-decoration: line-through;
  color: colors.$grey-6;
}

.discountSection {
  margin-top: 16px;

  .discountBtnText {
    color: colors.$grey-6;
    font-weight: 700;
    transition: all 250ms;
    margin-right: 10px;
    font-size: 1.6rem;

    button:hover & {
      color: colors.$nh-green;
    }
  }
}
