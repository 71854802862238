@use '../../styles/mixins/mq';
@use '../../styles/mixins/full-bleed';
@use '../../styles/mixins/font-styles';
@use '../../styles/mixins/body-max-width';
@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';

.titleContainer {
  margin-bottom: 32px;

  @include mq.mq($from: tablet) {
    margin-bottom: 40px;
  }

  .title {
    margin-right: 10px;
  }

  .titleInfoTrigger {
    cursor: pointer;

    &:hover {
      svg {
        circle {
          stroke: colors.$nh-green;
        }

        path {
          stroke: colors.$nh-green;
        }
      }
    }

    @include mq.mq($from: tablet) {
      position: relative;
    }

    svg {
      position: relative;
      top: 8px;
      @include mq.mq($from: tablet) {
        position: absolute;
        top: calc(50% - 8px);
        transform: translateY(-50%);
        width: 33px;
        height: 33px;
      }
    }
  }
}

.locationEditCtaWrapper {
  .locationEditCtaText {
    color: colors.$grey-5;
    font-weight: 700;
    font-size: 1.6rem;
  }

  .locationEditCta {
    display: inline-flex;
    padding: 0;
  }

  .locationIcon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
}

.bodyText {
  margin-bottom: 24px;

  @include mq.mq($from: tablet) {
    margin-bottom: 35px;
  }
}

.dropDownWrapper {
  margin-bottom: 24px;
}

.ptSelectionWrapper {
  @include mq.mq($from: tablet) {
    padding-top: 25px;
    border-top: 1px solid colors.$grey-3;
  }
}

.ptTypeHeading {
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-bottom: 0;

  @include mq.mq($from: tablet) {
    margin-bottom: 16px;
    text-decoration: none;
  }
}

.ptTypeHeadingIconSvg {
  path, circle {
    stroke: colors.$nh-green;
  }
}

.trainerType {
  display: inline;
}

.ptListContainer {
  .errorMessage p {
    color: colors.$excite-orange-dark;
    font-weight: 700;
  }
}

.modalControls {
  display: flex;
  margin-top: 30px;

  .modalControlsBtn {
    flex: 1 1 50%;

    &:first-child {
      display: flex;
      a {
        text-decoration: underline;
      }
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      button {
        @include font-styles.text-heavy-link;
      }
    }

  }
}

.modalContent {
  h2 {
    padding-bottom: 24px;
    border-bottom: 1px solid colors.$grey-3;
    margin-bottom: 0;
    @include mq.mq($from: tablet) {
      border-bottom: none;
      padding-bottom: 36px;
      text-align: center;
    }
  }

  @include mq.mq($from: tablet) {
    .ptStylesModalContent {
      display: flex;
      padding-bottom: 90px;

      .modalSection {
        flex: 1 1 33%;
        padding: 0 50px;
        border-bottom: none;

        .modalSectionTitle {
          margin-bottom: 12px;
        }
      }
    }

    .modalControlsSection {
      border-top: 1px solid colors.$grey-3;
      padding: 32px;
    }
  }
  
  .modalSection {
    border-bottom: 1px solid colors.$grey-3;
    padding: 24px 0;

    .modalSectionTitle {
      margin-bottom: 4px;
    }

    p {
      margin: 0;
    }

    ul {
      margin-bottom: 0;
    }
  }

  .modalControlsSection {
    text-align: center;
    padding: 24px;
  }
}

.locationModal {
  background-color: colors.$grey-1;
  padding-top: 16px !important;
}

.locationFinderModalWrapper {
  position: relative;

  @include mq.mq($from: desktop) {
    @include body-max-width.body-max-width;
    position: relative;
  }

  button {
    right: 0 !important;
  }
}

.locationErrorBox {
  margin-top: 10px;
}