@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';

.viewPaymentOptionsLink {
  font-size: 1.6rem;
  display: flex;
  justify-content: flex-end;

  @include mq.mq($from: tablet) {
    font-size: 1.8rem;
  }
}

.hiddenSubmit {
  display: none;
}

.modalTitle {
  margin-bottom: 24px;
}

.modalSection {
  border-top: 1px solid colors.$grey-3;
  padding: 24px 0;
}

.modalSubtitle {
  margin-bottom: 4px;
}

.modalButtonSection {
  text-align: center;
}

.errorWrapper {
  margin: 15px 0;
}

.selectionErrorMsg {
  color: colors.$danger-red;
  margin-top: 5px;
}

.changePtWrapper {
  margin-bottom: 32px;
  
  @include mq.mq($from: tablet) {
    margin-bottom: 40px;
  }
}

.changePtBtn {
  display: inline-flex;
  padding: 0;
  text-decoration: underline;
  font-weight: 700;
}

.changePtIcon {
  margin-right: 8px;
}

.submitWrapper {
  margin-top: 24px;
}