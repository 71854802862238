@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/mq';

.root {
  position: fixed;
  z-index: 1500;
  background-color: colors.$nh-white;
  font-size: 1.6rem;
  padding: 16px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  width: 95%;
  max-width: 768px;
  border: 1px solid colors.$grey-4;
  border-radius: 8px;

  &.fullScreen {
    top: 0;
    left: 0;
    transform: none;
    width: 100vw;
    height: 100vh;
    max-height: none;
    max-width: none;
    border: none;
    border-radius: 0;
    padding: 72px 24px 24px;

    @include mq.mq($from: tablet) {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  .fullScreenCloseBtn {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    &:after {
      content: "X";
      font-size: 20px;
      color: colors.$nh-green;
      font-family: fonts.$iconFont;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }

  .modalHeader {
    position: relative;
    padding-right: 25px;
    margin-bottom: 26px;
  }

  .modalCloseBtn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .title {
    font-weight: 700;
  }
}

.mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: colors.$nh-black;
  opacity: 0.45;
  z-index: 1400;
}