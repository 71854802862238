@use '../../styles/variables/colors';
@use '../../styles/mixins/font-sizes';

$loader-size: 120px;
$loader-line-width: 16px;

.root {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.background {
    background: rgba(244, 244, 244, 0.8);
  }

  .text {
    @include font-sizes.text;
    text-align: center;
    margin-top: 30px;
  }

  .loader {
    margin: 0 auto;
    border: $loader-line-width solid colors.$nh-green;
    border-top: $loader-line-width solid colors.$nh-white;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    animation: spin 1s linear infinite;
  }

  .loaderWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
  }
}
  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}