@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';

.removeModalTitle {
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    position: relative;
    bottom: 1px;
  }
  path {
    fill: colors.$excite-orange-dark;
  }
}

.removeBtn {
  margin-top: 16px;
  text-align: center;

  button {
    font-weight: 700;
  }
}

.removeModalButtons {
  display: flex;

  div {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
  }
}

.errorWrapper {
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  font-size: 1.4rem;
  background-color: rgba(172, 52, 0, 0.3);
}