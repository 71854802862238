@use './mq';

@mixin h1 {
  font-size: 3.2rem;
  line-height: 38px;

  @include mq.mq($from: tablet) {
    font-size: 4.8rem;
    line-height: 62px;
  }
}

@mixin h2 {
  font-size: 2.4rem;
  line-height: 30px;
  font-weight: 700;

  @include mq.mq($from: tablet) {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 47px;
  }
}

@mixin h3 {
  font-size: 2.1rem;
  line-height: 26px;
  font-weight: 700;

  @include mq.mq($from: tablet) {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 42px;
  }
}

@mixin h4 {
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: 700;

  @include mq.mq($from: tablet) {
    font-size: 2.4rem;
    line-height: 31px;
  }
}

@mixin h5 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 21px;

  @include mq.mq($from: tablet) {
    font-size: 2rem;
    line-height: 26px;
  }
}

@mixin h6 {
  font-size: 1.6rem;
  line-height: 21px;
  font-weight: 700;

  @include mq.mq($from: tablet) {
    font-size: 1.8rem;
    line-height: 20px;
  }
}

@mixin text {
  font-size: 1.6rem;

  @include mq.mq($from: tablet) {
    font-size: 1.8rem;
  }
}