@use '../../styles/mixins/mq';

.errorContainer {
  margin-bottom: 15px;
}

.paymentMethod {
  iframe {
    @include mq.mq($until: tablet) {
      width: 450px !important;
    }
    @include mq.mq($until: largeMobile) {
      width: 320px !important;
    }
    @include mq.mq($until: 400px) {
      width: 270px !important;
    }
  }
}