@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/mq';

.searchFormWrapper {
  max-width: 500px;
}

.searchBarWrapper {
  position: relative;
  margin-bottom: 25px;

  svg {
    position: absolute;
    right: 10px;
    top: 25px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 150;
  }

  input {
    border-radius: 5px;
  }
}

.geolocationBtn {
  font-weight: 700;
  margin-bottom: 24px;

  svg {
    margin-right: 10px;
  }
}

.searchView {
    @include mq.mq($from: tablet) {
      padding-top: 20px;
    }
}

.gymResultsHr {
  height: 1px; 
  background-color: colors.$grey-4;
  max-width: 920px;
  margin: 32px auto 10px;
  
  @include mq.mq($from: tablet) {
    margin: 40px auto;
  }
}