@use '/src/styles/variables/colors';
@use '/src/styles/variables/spacings';
@use '/src/styles/variables/sizings';
@use '../../styles/mixins/mq';

.root {
    padding: 50px spacings.$gutters 16px;
    position: sticky;
    top: 0;
    background: colors.$nh-white;
    z-index: 1000;

    @include mq.mq($until: tablet) {
        height: sizings.$header-height-mobile;
    }

    @include mq.mq($from: tablet) {
        padding-top: 25px;
    }

    .headingWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 16px;
    }

    .logo {
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: colors.$nh-green;
        margin-bottom: 10px;

        @include mq.mq($from: tablet) {
            margin-bottom: 6px;
        }
    }

    .titleWrapper {
        width: 100%;

        @include mq.mq($from: tablet) {
            position: relative;
        }

        .title {
            text-align: center;
            color: colors.$nh-green;
        }

        .back {
            position: absolute;
            left: spacings.$gutters;
            top: 10px;

            @include mq.mq($from: tablet) {
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.isScrolled {
    height: sizings.$header-height-mobile-scrolled;
    padding: 11px spacings.$gutters 10px;
    display: flex;
    justify-content: center;

    .headingWrapper {
        padding-bottom: 0;
    }

    .logo,
    .back {
        display: none;
    }

    .progressBar {
        display: none;
    }
}