@use '/src/styles/variables/colors';
@use '../../styles/mixins/mq';

.title {
  margin: 24px 0 8px 0;
}

.fieldWrapper {
  display: flex;
}

.paragraph {
  color: colors.$grey-6;
  margin-bottom: 12px;
}

.submitBtn {
  color: colors.$nh-dark-green;
  font-weight: 800;
  background-color: colors.$nh-white;
  border: 2px solid colors.$nh-dark-green;
  border-radius: 100px;
  padding: 6px 24px;
  cursor: pointer;
  height: 52px;
  
  @include mq.mq($from: tablet) {
    padding: 6px 32px;
  }

  &:hover {
    color: colors.$balance-green-dark;
    border-color: colors.$balance-green-dark;
  }

  &.disabled,
  &:hover.disabled {
    background-color: colors.$nh-white;
    color: colors.$grey-5;
    border-color: colors.$grey-8;
  }
}

.discountFormField {
  position: relative;

  input {
    border: 2px solid colors.$grey-8;
    width: 200px;
    height: 52px;
    padding: 16px 32px;
    margin-right: 24px;

    @include mq.mq($from: tablet) {
      width: 325px;
    }
  }
}

.errorIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 275px;
  top: 10px;
  cursor: pointer;

  svg path {
    fill: colors.$excite-orange-dark;
  }
}

.invalidCodeErrorMsg {
  margin-top: 12px;
  width: 325px;

  p {
    color: colors.$danger-red;
    margin-bottom: 0;
  }
}

.validCodeSuccessMsg {
  position: relative;
  background-color: colors.$nh-pale-green;
  padding: 16px 24px;
  margin-top: 24px;

  p {
    color: colors.$nh-green;
    margin-bottom: 0;
  }

  .validCodeMsgIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
}

.form {
  position: relative;
}