@use '/src/styles/variables/colors';

.button {
  display: flex;
  width: 100%;
  padding: 16px;
  cursor: pointer;

  &:hover {
    .radio {
      background-color: colors.$grey-2;
    }
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.radio {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: colors.$nh-white;
  border-radius: 50%;
  border: 2px solid colors.$grey-3;
  transition: 250ms all;
  margin-right: 15px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: colors.$nh-green;
  }

  &.radioActive {
    &:after {
      display: block;
    }
  }
}
