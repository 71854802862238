@use '../../styles/variables/fonts';
@use '../../styles/variables/colors';
@use '/src/styles/mixins/mq';

.body {
  margin-bottom: 30px;
}
.pillButtons {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 30px;

  @include mq.mq($from: tablet) {
    display: inline-flex;
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;
  }
}