$excite-yellow: #FFC82C;
$excite-yellow-dark: #785D19;
$excite-orange: #FFA257;
$excite-orange-dark: #AC3500;
$excite-blue: #8DC8E8;
$excite-blue-dark: #085482;
$balance-green: #B8CA2F;
$balance-green-dark: #2F4F2D;
$balance-pale: #F0C4B2;
$balance-pale-dark: #AB5A5A;
$balance-blue: #8FA2C4;
$balance-blue-dark: #322A5A;
$recover-purple: #C88590;
$recover-purple-dark: #471E3D;
$recover-teal: #B3D3D8;
$recover-teal-dark: #005C6B;
$recover-stone: #B7A99A;
$recover-stone-dark: #3E2D2B;
$nh-green: #00A200;
$nh-white: #FFFFFF;
$nh-black: #282823;
$grey-1: #F5F5F5;
$grey-2: #E2E2E2;
$grey-3: #D4D4D4;
$grey-4: #DFDFDF;
$grey-5: #5F6062;
$grey-6: #333333;
$grey-7: #A2A2A2;
$grey-8: #8F9091;
$error-state-red: #AA0000;
$n-a: #FFA257;
$n-a: #FFC79A;
$n-a: #FFE3CC;
$n-a: #FFF5EE;
$n-a: #FFEEBF;
$n-a: #FFF9E9;
$n-a: #AC3500;
$n-a: #785D19;
$n-a: #F0C4B2;
$n-a: #F6DCD1;
$n-a: #FAEDE7;
$n-a: #FDF9F7;
$n-a: #AB5A5A;
$jungle-mist: #B3D3D8;
$ziggurat: #D1E5E8;
$catskill-white-1: #E8F1F3;
$catskill-white-2: #F7FAFB;
$sherpa-blue: #005C6B;
$puce: #C88590;
$blossom: #DEB6BC;
$pink-flare-1: #EEDADD;
$soft-peach: #F9F2F3;
$finn: #471E3D;
$green-tint: #DDECDD;
$dark-green: #10710E;
$nh-dark-green: #3E7D34;
$nh-light-green: #6bb565;
$nh-pale-green: #E3EDE2;
$pure-black: #000000;
$nh-pale-blue: #F3F9FC;
$excite-pale-orange: #FEF6F1;
$danger-red: #E50707;
$dark-burgundy: #68060C;