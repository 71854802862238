@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';
@use '../../styles/mixins/font-sizes';
@use '../../styles/variables/fonts';

$gym-card-margin-top-desktop: 21px;
$gym-card-margin-top-mobile: 26px;

.gymCard {
  position: relative;
  border: 1px colors.$grey-3 solid;
  padding: $gym-card-margin-top-mobile 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: colors.$nh-white;
  min-height: 230px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include mq.mq($until: tablet) {
    margin-bottom: 32px;
  }

  @include mq.mq($from: tablet) {
    padding: $gym-card-margin-top-desktop 16px 37px;
    min-height: 260px;
  }

  .details {
    flex: 1 1 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .gymName {
    padding-right: 100px;

    @include mq.mq($from: tablet) {
      @include font-sizes.h4;
      font-weight: 700;
    }
  }

  .distance {
    position: absolute;
    top: $gym-card-margin-top-mobile;
    right: 16px;
    font-size: 16px;
    color: colors.$grey-5;

    @include mq.mq($from: tablet) {
      top: $gym-card-margin-top-desktop;
    }
  }

  .addressDetails {
    font-size: 1.6rem;
    color: colors.$grey-5;
  }

  .phoneNumber {
    font-size: 1.8rem;
  }

  ul {
    margin-bottom: 16px;
    font-size: 1.4rem;
  }

  svg {
    margin-top: 8px;
    margin-right: 8px;
  }

  .buttonWrapper {
    padding: 0 20px;
  }
}