@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';

.notificationCard {
  border-radius: 6px;
  padding: 16px;
  border: 2px solid colors.$grey-5;
  max-width: 500px;

  &.danger {
    border-color: colors.$danger-red;
    background-color: colors.$excite-pale-orange;
  }

  &.warning {
    border-color: colors.$excite-orange;
    background-color: colors.$excite-pale-orange;
  }

  &.info {
    border-color: colors.$jungle-mist;
    background-color: colors.$excite-pale-orange;
  }

  &.success {
    border-color: colors.$nh-green;
    background-color: colors.$excite-pale-orange;
  }

  &.fullWidth {
    max-width: none;
    width: 100%;
  }

  .heading {
    display: flex;
    gap: 5px;
  }

  .headingText {
    display: flex;
    align-items: center;
    @include mq.mq($until: tablet) {
      position: relative;
      top: 2px;
    }
  }

  .iconWrapper {
    @include mq.mq($until: tablet) {
      display: flex;
      align-items: flex-start;
    }
  }

  .icon {
    width: 22px;
    height: 22px;

    @include mq.mq($from: tablet) {
      width: 28px;
      height: 28px;
    }
  }
}