@use '/src/styles/mixins/mq';
@use '/src/styles/variables/colors';

.headingSection {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  
  @include mq.mq($from: tablet) {
    padding-bottom: 0;
    cursor: default;
  }
}

.headingElemWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 10px; 
}

.headingIcon {
  display: flex;
  align-items: center;
  padding-right: 10px; 

  @include mq.mq($from: tablet) {
    display: none;
  }
}

.headingChevWrapper {
  width: 40px;
  display: flex;
  align-items: center;

  @include mq.mq($from: tablet) {
    display: none;
  }
}

.chevron {
  stroke: colors.$nh-green;
  transition: transform 350ms;

  path {
    fill: colors.$nh-green;
  }
}

.chevronRotate {
  transform: rotate(180deg);
}

.bodyWrapper {
  max-height: 0;
  overflow: hidden;
  transition: all 350ms;
}

.bodyPanel {
  padding: 18px 6px;

  @include mq.mq($from: tablet) {
    padding: 0;
  }
}
