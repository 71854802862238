@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';

.editButtonText {
    font-weight: 700;
    font-size: 1.6rem;
    color: colors.$grey-6;
    transition: all 250ms;
    text-decoration: underline;
    margin-right: 8px;
  
    button:hover & {
      color: colors.$nh-green;
    }
  }
  
  .editIcon {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 4px;
  
    @include mq.mq($from: tablet) {
      top: 2px;
    }
  
    svg {
      circle {
        stroke: colors.$grey-6;
        transition: all 250ms;
  
        button:hover & {
          stroke: colors.$nh-green;
        }
      }
  
      path {
        fill: colors.$nh-green;
        stroke: colors.$nh-green;
        transition: all 250ms;
  
        button:hover & {
          fill: colors.$nh-green;
          stroke: colors.$nh-green;
        }
      }
    }
  }