@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/mq';
@use '../../styles/mixins/font-sizes';

.item {
  margin-bottom: 16px;
  color: colors.$grey-6;

  p {
    font-size: 1.6rem;

    @include mq.mq($from: tablet) {
      font-size: 1.8rem;
    }
  }
}

.itemTop {
  display: flex;
}

.titleWrapper {
  flex: 1 1 50%;
}

.title {
  margin-bottom: 8px;
}

.editWrapper {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;
}

svg.plusMinusIcon {
  &:hover {
    rect {
      transition: all 250ms;
      stroke: colors.$nh-green;
    }

    cursor: pointer;
  }
}

svg.iconDisabled {
  path {
    stroke: #D4D4D4;
  }

  pointer-events: none;
}

.total {
  display: flex;
  color: colors.$grey-6;
  padding-top: 24px;
  border-top: 1px solid colors.$grey-3;
}

.totalLabel {
  flex: 1 1 50%;
  font-size: 1.8rem;
  font-weight: 700;
  color: colors.$grey-6;
}

.totalPriceContainer {
  @include font-sizes.text;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  color: colors.$grey-6;
  align-items: flex-end;
}

.strikethroughPrice {
  text-decoration: line-through;
  color: colors.$grey-6;
}



.sectionTitle {
  display: flex;
}

.itemWrapper {
  @include mq.mq($from: tablet) {
    flex: 1 1 50%;
    padding-right: 25px;
  }
}

.infoWrapper {
  @include mq.mq($from: tablet) {
    flex: 1 1 50%;
    padding-left: 25px;
  }
}

.closeModalText {
  font-weight: 700;
}

.closeBtn {
  text-align: center;
}

.removeButtonWrapper {
  display: flex;
  justify-content: center;
}

.itemPackageWrapper {
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .editWrapper {
    align-items: center;

    .info {
      position: relative;
      min-width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin: 0 10px;
    }
  }
}

.info {
  display: inline-flex;

  p {
    padding-left: 8px;
  }

  svg {
    path {
      fill: colors.$nh-green;
      stroke: colors.$nh-green;
      transition: all 250ms;
    }

    circle {
      stroke: colors.$nh-green;
      transition: all 250ms;
    }
  }
}