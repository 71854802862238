@use '../../styles/variables/colors';
@use '../../styles/mixins/font-styles';

.labelTag {
  display: flex;
  cursor: pointer;
}

.switchWrapper {
  flex: 1 1 60px;
  max-width: 60px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colors.$grey-5;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .switchWrapper .slider {
  background-color: colors.$nh-green;
}

input:focus + .switchWrapper .slider {
  box-shadow: 0 0 1px colors.$nh-green;
}

input:checked ~ .labelWrapper {
  span {
    color: colors.$grey-6;
  }
}

input:checked + .switchWrapper .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.innerIcons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.crossIcon {
  right: 7px;
}

.tickIcon {
  left: 7px;
}

.labelWrapper {
  flex: 1 1 auto;
  padding-left: 15px;
  padding-top: 3px;
  line-height: 25px;
  @include font-styles.text;
}