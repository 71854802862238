@use '../../styles/mixins/mq';
@use '../../styles/variables/colors';
@use '../../styles/variables/fonts';
@use '../../styles/mixins/full-bleed';
@use '/src/styles/variables/sizings';

$mobileFilterControlHeight: 75px;

.mobileFilterTrigger {
  @include full-bleed.full-bleed;
  background-color: colors.$grey-1;
  border-top: 1px solid colors.$grey-2;
  border-bottom: 1px solid colors.$grey-2;
  box-shadow: 0px 1px 4px 0px colors.$grey-6;
  padding: 8px 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.6rem;
  color: colors.$grey-6;
  transition: background-color 250ms;

  &:hover {
    background-color: colors.$grey-2;
  }

  .mobileFilterTriggerIconWrapper {
    margin: 0 10px;
    position: relative;
    display: flex;

    .mobileFilterCounter {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 1.4rem;
      color: colors.$nh-white;
      background-color: colors.$error-state-red;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .mobileFilterTriggerIcon path {
    fill: colors.$grey-6;
  }

  @include mq.mq($from: tablet) {
    display: none;
  }
}

.ptLister {
  @include mq.mq($from: tablet) {
    display: flex;
  }
}

.ptFilterWrapper {
  display: none;

  @include mq.mq($from: tablet) {
    display: block;
    padding-right: 39px;
    flex: 1 1 33%;
  }
}

.ptListerHeading {
  @include mq.mq($until: tablet) {
    font-size: 2.1rem;
  }
}

.ptFilterMainHeading {
  margin-bottom: 0;
}

.ptCardLister {
  flex: 1 1 100%;

  @include mq.mq($from: tablet) {
    flex: 1 1 66%;
    border-left: 1px solid colors.$grey-3;
    padding-left: 40px;
  }

  @include mq.mq($from: largeTablet) {
    padding-left: 70px;
  }

  .ptListRow {
    margin-bottom: 32px;

    @include mq.mq($from: tablet) {
      margin-bottom: 40px;

      &.bioOpenInRow {
        .ptCardContainer {
          padding-bottom: 24px;
        }
      }
    }

    .rowCardWrapper {
      @include mq.mq($from: largeTablet) {
        display: grid;
        grid-template-columns: 1fr 1fr; 
        gap: 40px 40px;
      }
    }
  }
  
  .collapsiblePanel {
    .collapsiblePanelInner {
      -webkit-box-shadow: inset 0px 10px 8px -6px rgba(204,204,204,0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
      box-shadow: inset 0px 10px 8px -6px rgba(204,204,204,0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 24px 24px;
      width: 100%;
      border-top: 2px solid colors.$nh-dark-green;
      position: relative;

      .closePanelButtonWrapper {
        position: absolute;
        top: 17px;
        right: 14px;

        button {
          font-size: 1.6rem;

          .closeBioIcon {
            margin-left: 10px;
          }
        }
      }

      .panelBottomRow {
        @include mq.mq($from: tablet) {
          margin-top: 30px;
          display: flex;
        }
      }

      .profileDetails {
        flex: 1 1 50%;

        @include mq.mq($until: tablet) {
          margin-bottom: 16px;
        }

        .subheading {
          display: flex;
          align-items: center;
          color: colors.$grey-5;
          font-size: 2.4rem;
          margin-bottom: 22px;

          &.topSubheading {
            margin-top: 30px;
          }
        }

        .subheadingIcon {
          margin-right: 20px;
        }
  
        &:first-child {
          padding-left: 0;
        }    
      }

      .testimonial {
        flex: 1 1 50%;
        background-color: colors.$nh-white;
      }

      .ctaBtn {
        display: flex;
        justify-content: center;
        padding: 9px 0;
        width: 100%;
      }
      
      @include mq.mq($from: largeTablet) {
          padding: 24px 36px;
      }
    }
  }
}

.noPtFound {
  margin-top: 40px;
  text-align: center;
}

.ptListerHeadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.ptFilterInnerWrapper {
  @include mq.mq($from: tablet) {
    position: sticky;
    top: 100px;
  }
}

.mobileFilterTriggerButton {
  @include mq.mq($until: tablet) {
    z-index: 500;
    cursor: pointer;
    position: sticky;
    width: 100%;
    top: sizings.$header-height-mobile-scrolled;
  }
}

.mobileFilterModal {
  padding: 16px 0 !important;
}

.mobileFilterModalCloseButton {
  padding: 0 16px;
  margin-bottom: 32px;
  color: colors.$grey-5;
}

.mobileFilterModalCloseIcon {
  margin-right: 6px;
}

.mobileModalHeading {
  margin-bottom: 24px;
  text-align: center;
}

.mobileModalFilterWrapper {
  padding-bottom: $mobileFilterControlHeight;
}

.mobileFilterControls {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid colors.$grey-4;
  background-color: colors.$nh-white;
  max-height: $mobileFilterControlHeight;
}

.mobileFilterModalInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mobileFilterNoResults {
  flex: 1 1 100%;
  display: flex;
  text-align: center;
  background-color: colors.$grey-1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileFilterBarClear {
  font-weight: 600 !important;
  font-size: 1.6rem;
  color: colors.$grey-6 !important;
}