@use '/src/styles/variables/colors';
@use '/src/styles/variables/fonts';
@use '/src/styles/mixins/font-sizes';
@use '../../styles/mixins/mq';

.wrapper {
  position: relative;
  width: 100%;
  @include font-sizes.text;
}

.box {
  background: colors.$nh-pale-green;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;

  @include mq.mq($from: tablet) {
    padding: 32px;
  }
}

.sectionWrapper {
  border-top: solid 1px colors.$grey-3;
  padding: 2.4rem 0;

  &:first-of-type {
    margin-top: 2.4rem;
  }
}

h4.boxHeading {
  margin-bottom: 16px;
  font-size: 2rem;

  @include mq.mq($from: tablet) {
    font-size: 2.4rem;
  }
}

.errorWrapper {
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: rgba(172, 52, 0, 30%);
}

.checkboxContainer {
  padding: 16px 0 0;
  color: colors.$grey-6;

  a {
    font-weight: bold;
  }

  .newWindow {
    display: inline-flex;
    color: colors.$grey-5;

    p {
      padding-left: 48px;
      display: flex;
      flex-direction: row-reverse;
      font-size: 1.6rem;
      align-items: center;

      @include mq.mq($from: tablet) {
        flex-direction: row;
      }
    }

    svg {
      margin: 0 8px 0 0;
      width: 18px;
      height: 18px;

      @include mq.mq($from: tablet) {
        width: 24px;
        height: 24px;
        margin: 0 0 0 8px;
      }
    }
  }
}