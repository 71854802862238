@use './mq';
@use './font-sizes';
@use '../variables/fonts';
@use '../variables/colors';

@mixin text {
  @include font-sizes.text;
  font-family: fonts.$bodyFont;
  color: colors.$grey-5;
  line-height: fonts.$bodyLineHeight;
}

@mixin text-secondary {
  @include text; // Inherit from primary above
  font-size: 1.6rem;
  color: colors.$grey-6;

  @include mq.mq($from: tablet) {
    font-size: 1.8rem;
    color: colors.$grey-5;
  }
}

@mixin text-banner-desc {
  @include font-sizes.text;
  font-family: fonts.$headingFont;
  font-weight: 300;
  color: colors.$grey-6;

  @include mq.mq($from: tablet) {
    font-size: 2rem;
  }
}

@mixin text-link($color: colors.$grey-5, $hoverColor: colors.$nh-green) {
  font-size: 1.6rem;
  font-family: fonts.$bodyFont;
  color: $color;
  text-decoration: underline;
  cursor: pointer;
  transition: 250ms all;

  &:hover {
    text-decoration: none;
    color: $hoverColor;
  }

  @include mq.mq($from: tablet) {
    font-size: 1.6rem;
  }
}

@mixin text-heavy-link($color: colors.$grey-6, $hoverColor: colors.$nh-green) {
  @include text-link($color, $hoverColor);
  font-family: fonts.$bodyHeavyFont;
  font-weight: 700;
}

@mixin text-black-link($color: colors.$grey-6, $hoverColor: colors.$nh-green) {
  @include text-link($color, $hoverColor);
  font-family: fonts.$bodyBlackFont;
  font-weight: 900;
}

@mixin headings {
  font-family: fonts.$headingFont; 
  color: colors.$grey-6;
  margin-bottom: 8px;

  &.bold {
    font-weight: 700;
  }
  
  &.noMargin {
    margin-bottom: 0;
  }
}