@use '/src/styles/variables/colors';
@use '/src/styles/variables/fonts';
@use '/src/styles/mixins/font-sizes';
@use '/src/styles/mixins/font-styles';
@use '/src/styles/mixins/mq';

.input {
  @include font-styles.text;
  border: 1px solid colors.$grey-4;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  height: 50px;
  transition: border-color 250ms;

  &:focus, &:hover {
    border: 1px solid colors.$nh-green;
  }

  &::placeholder {
    color: colors.$grey-5;
  }
}

.error {
  .input {
    border: 2px solid colors.$excite-orange-dark;
  }
}

.errorMessage {
  margin-top: 7px;  
  color: colors.$excite-orange-dark;
  font-weight: 700;
  font-size: 1.4rem;

  @include mq.mq($from: tablet) {
    font-size: 1.6rem;
  }
}

.label {
  font-family: fonts.$headingFont;
  margin-bottom: 12px;
  display: inline-block;
  @include font-sizes.text;
  @include mq.mq($from: tablet) {
    color: colors.$grey-5;
  }
}