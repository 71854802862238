@use '../styles/variables/colors';
@use '../styles/mixins/font-styles';
@use '../styles/variables/fonts';

.pageSection {
  padding-bottom: 24px;
}

.introText {
  
  .title,
  h2 {
    margin-bottom: 8px;
  }
  h1 {
    margin-bottom: 55px;
    font-family: fonts.$headingFont;
  }
  
  p {
    @include font-styles.text;
  }
}