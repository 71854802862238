@use '../../styles/mixins/mq';
@use '../../styles/variables/colors';

.filterPanel {
  border: 1px solid colors.$grey-8;
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 12px 16px;

  @include mq.mq($until: tablet) {
    border: none;
    padding: 12px 0;
  }
}

.filterPanelHeadingWrapper {
  @include mq.mq($until: tablet) {
    margin: 0 16px;
  }
}

.filterPanelHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  @include mq.mq($until: tablet) {
    font-size: 1.8rem;
    border-bottom: 1px solid colors.$grey-4;
  }
}

.filterPanelHeadingText {
  width: 100%;
  margin-right: 5px;
  color: colors.$grey-5;
}

.filterPanelHeadingIcon {
  position: relative;
  top: 2px;
  path {
    fill: colors.$nh-green;
  }
}

.filtersContainer {
  margin-top: 20px;
}

.filterOptionContainer {
  border-bottom: 1px solid colors.$grey-4;
  margin-bottom: 12px;

  @include mq.mq($until: tablet) {
    border-bottom: none;
    margin-bottom: 6px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  label {
    margin-bottom: 12px;
  }
}