@use '/src/styles/variables/colors';
@use '/src/styles/variables/fonts';
@use '/src/styles/mixins/mq';

.mobileCopy {
  font-size: 1.4rem;
  color: colors.$grey-8;

  @include mq.mq($from: tablet) {
    display: none;
  }
}

.desktopCopy {
  display: none;
  color: colors.$nh-black;
  font-size: 1.6rem;
  line-height: 26px;
  text-align: center;

  @include mq.mq($from: tablet) {
    display: block;
  }
}

.stepList {
  display: flex;

  @include mq.mq($from: tablet) {
    justify-content: space-between;
  }
}

.step {
  flex: 1 1 auto;
  text-align: center;

  // START DESKTOP STYLING
  @include mq.mq($from: tablet) {
    flex: 0 0 var(--desktop-step-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 4px;
      background-color: colors.$grey-4;
      width: var(--step-list-line-between-width);
      z-index: 1;
    }

    &:before {
      top: 15px;
      right: calc(50% + 15px);
    }

    &:after {
      top: 15px;
      left: calc(50% + 15px);
    }

    &:first-child:before {
      display: none;
    }

    &:last-child:after {
      display: none;
    }
  }

  .stepNumber {
    display: none;

    @include mq.mq($from: tablet) {
      width: var(--desktop-step-number-width);
      height: var(--desktop-step-number-width);
      flex: 0 0 var(--desktop-step-number-width);
      border-radius: 50%;
      border: 2px solid colors.$grey-5;
      justify-content: center;
      align-items: center;
      font-family: fonts.$headingFont;
      font-weight: 700;
      margin-bottom: 12px;
      padding-top: 2px; // required to adjust position for nuffield sans
      display: flex;
    }
  }

  // END DESKTOP STYLING

  .stepUnderLine {
    height: 4px;
    width: 100%;
    background-color: colors.$nh-pale-green;

    @include mq.mq($from: tablet) {
      display: none;
    }
  }

  &:first-child {
    .stepUnderLine {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &:last-child {
    .stepUnderLine {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .chevronWrapper {
    height: 20px;
    display: flex;
    justify-content: center;

    @include mq.mq($from: tablet) {
      display: none;
    }
  }

  .activeChevron {
    display: none;
  }

  &.active,
  &.complete {
    .mobileCopy {
      color: colors.$nh-black;
    }

    .stepNumber {
      border-color: colors.$nh-dark-green;
      background-color: colors.$nh-dark-green;
      color: colors.$nh-white;
    }

    .stepUnderLine {
      background-color: colors.$nh-dark-green;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:before {
      background-color: colors.$nh-dark-green;
    }
  }

  &.active {
    @include mq.mq($until: tablet) {
      font-weight: 600;

      .activeChevron {
        display: block;
      }
    }
  }

  &.complete {

    // Desktop lines between steps
    &:after {
      background-color: colors.$nh-dark-green;
    }

    // Mobile styles
    .mobileCopy {
      color: colors.$grey-8;
    }

    .stepUnderLine {
      background-color: colors.$nh-dark-green;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.isScrolled {
    display: none;
}