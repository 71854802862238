@use '../../styles/variables/colors';
@use '../../styles/mixins/mq';
@use '../../styles/mixins/font-sizes';

.root {
  border-top: 1px solid colors.$grey-3;
  border-bottom: 1px solid colors.$grey-3;
  padding-top: 8px;
  margin-bottom: 16px;

  @include mq.mq($from: tablet) {
    margin-bottom: 0;
  }

  &.optionChecked {
    border-color: colors.$nh-green;
  }

  .option {
    color: colors.$grey-5;
  }

  .optionRow {
    display: flex;
    justify-content: space-between;
  }

  .optionTitle {
    @include font-sizes.text;
    margin: 5px 15px 16px 0;
  }

  .topRight {
    padding-top: 5px;

    .optionDetails {
      text-align: right;
    }
  }

  .priceWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include mq.mq($from: tablet) {
      flex-direction: column;
      align-items: flex-end;
    }

    &.hasWasPrice {      
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .optionDetails {
    @include font-sizes.text;
  }

  .wasPrice {
    text-decoration: line-through;
  }

  .badges {
    margin-left: 8px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    @include mq.mq($from: tablet) {
      flex-direction: column-reverse;
    }
  }

  .badgeWrapper {
    text-align: right;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 5px;
    }

    @include mq.mq($from: tablet) {
      margin-bottom: 0;
    }
  }

  .discountBadgeText {
    font-weight: 700;
    color: colors.$grey-6;
  }

  .price {
    margin-top: 5px;
    margin-left: 8px;
  }
}
