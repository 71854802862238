@use '../../styles/variables/colors';
@use './Button.module.scss';

.root {
  text-decoration: none;
  cursor: pointer;

  &.button {
    @extend .root; // from button module

    &.inlineFlex {
      display: inline-flex;
    }
  }  
}